/* eslint-disable indent */
import React, { useState, useCallback, useMemo } from "react";
import { useQuery } from "hooks";
import { makeStyles } from "@material-ui/core/styles";
import { useAccount } from "context/account.context";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";

import { AuthModal } from "components";

import ReceiptIcon from "@mui/icons-material/Receipt";

import Moment from "react-moment";

const useStyles = makeStyles(() => ({
  table: {
    marginTop: "20px",
  },
}));

const estadosPedidos = [
  null,
  "EN PROCESO",
  "PENDIENTE DE PAGO",
  "PAGADO",
  "PENDIENTE DE ENVIO",
  "ENVIADO",
  "ENTREGADO",
  "CANCELADO",
  "RECHAZADO",
  "EXPIRADA",
  "REEMBOLSADO",
  "PAGO RECHAZADO",
];

export default function MisPedidos() {
  const classes = useStyles();
  const account = useAccount();

  const [orders, setOrders] = useState([]);
  const token = useMemo(() => localStorage.getItem("token"), []);

  const onCompleted = useCallback((response) => {
    setOrders(response.orders);
    return response;
  }, []);

  useQuery({
    url: "/user/pedidos",
    onCompleted,
    onError: console.log,
    skip: !token,
  });

  const handleDownloadFactura = (invoice_number) => {
    var baseURL =
      process.env.REACT_APP_API_URL ||
      "https://buy.bigbenespana.es/restapi/api";

    fetch(`${baseURL}/pedidos/factura/${invoice_number}`, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${invoice_number}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        return Promise.reject({ Error: "Something Went Wrong", err });
      });
  };

  return (
    <div className="container-fluid" style={{ margin: "2% 10%" }}>
      <AuthModal open={!account.isLoggedIn} />

      <h1 style={{ textAlign: "center" }}>Mis Ordenes</h1>
      {orders ? (
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Número</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Datos de envío</TableCell>
                <TableCell>Total Neto</TableCell>
                <TableCell>Descuento</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Fecha compra</TableCell>
                <TableCell>Factura</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell component="th" scope="row">
                    {order.numero}
                  </TableCell>
                  <TableCell>{order.email}</TableCell>
                  <TableCell>
                    <p>
                      <b>{order.nombre_envio + " " + order.apellidos_envio}</b>
                    </p>
                    <p>{order.direccion_envio}</p>
                    <p>
                      {order.localidad_envio +
                        ", " +
                        order.cp_envio +
                        ", " +
                        order.provincia_envio +
                        ", " +
                        order.pais_envio}
                    </p>
                    <p>{order.telefono_envio}</p>
                  </TableCell>
                  <TableCell>{order.total_neto.toFixed(2)} €</TableCell>
                  <TableCell>
                    {order.descuento_aplicado
                      ? order.descuento_aplicado.toFixed(2) + " €"
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {estadosPedidos[order.id_pedido_estado]}
                  </TableCell>
                  <TableCell>
                    <Moment format="DD/MM/YYYY HH:mm">
                      {order.created_at}
                    </Moment>
                  </TableCell>
                  <TableCell>
                    {order.factura ? (
                      <IconButton
                        style={{ color: "#348ceb" }}
                        onClick={() => {
                          handleDownloadFactura(order.factura.invoice_number);
                        }}
                      >
                        <ReceiptIcon />
                      </IconButton>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="d-flex flex-items-center flex-justify-center flex-1">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
