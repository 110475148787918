import { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLazyQuery } from "hooks";
import { useCallback } from "react";

export const LOAD_CART = "LOAD_CART";
export const INCREMENT_LINE_QUANTITY = "INCREMENT_LINE_QUANTITY";
export const DECREMENT_LINE_QUANTITY = "DECREMENT_LINE_QUANTITY";
export const UPDATE_LINE_QUANTITY = "UPDATE_LINE_QUANTITY";
export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const DELETE_LINE = "DELETE_LINE";
export const LOAD_CART_ERROR = "LOAD_CART_ERROR";

const initialState = {
  id: undefined,
  tmp: undefined,
  totales: undefined,
  id_pedido_estado: undefined,
  promocion: undefined,
  site: {
    id: undefined,
    name: undefined,
    image: undefined,
    corp_logo_black: undefined,
    url: undefined,
    tpv_merchant_code: undefined,
    tpv_name: undefined,
    url_policies: undefined,
    url_cookies: undefined,
    url_condiciones_venta: undefined,
    buy_url: undefined,
    amazon_merchant_id: undefined,
    amazon_public_key_id: undefined,
    amazon_sandbox_public_key_id: undefined,
    amazon_sandbox: undefined,
  },
  lines: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case LOAD_CART_ERROR:
      return {
        ...state,
        site: {
          ...state.site,
          image: action.payload,
        },
      };
    case LOAD_CART:
      return {
        id: action.payload.id,
        tmp: action.payload.tmp,
        id_pedido_estado: action.payload.id_pedido_estado,
        promocion: action.payload.promocion,
        totales: {
          total_neto: action.payload.total_neto,
          total_bruto: action.payload.total_bruto,
          total_iva: action.payload.total_iva,
          total_base_neto: action.payload.total_base_neto,
          total_descuento_neto: action.payload.total_descuento_neto,
          coste_envio_neto: action.payload.coste_envio_neto,
          descuento_aplicado: action.payload.descuento_aplicado,
        },
        site: {
          id: action.payload.sitio.id,
          name: action.payload.sitio.nombre,
          image: action.payload.sitio.url_logo,
          corp_logo_black: action.payload.sitio.corp_logo_black,
          url: action.payload.sitio.url,
          tpv_merchant_code: action.payload.sitio.tpv_merchant_code,
          tpv_name: action.payload.sitio.tpv_name,
          url_policies: action.payload.sitio.url_policies,
          url_cookies: action.payload.sitio.url_cookies,
          url_condiciones_venta: action.payload.sitio.url_condiciones_venta,
          buy_url: action.payload.sitio.buy_url,
          amazon_merchant_id: action.payload.sitio.amazon_merchant_id,
          amazon_public_key_id: action.payload.sitio.amazon_public_key_id,
          amazon_sandbox_public_key_id:
            action.payload.sitio.amazon_sandbox_public_key_id,
          amazon_sandbox: action.payload.sitio.amazon_sandbox,
        },
        lines: action.payload.lineas_producto.map((linea) => ({
          id: linea.id,
          product: {
            id: linea.producto.id,
            ean: linea.producto.ean,
            sku: linea.producto.referencia,
            title: linea.producto.nombre_de_producto_es,
            description: linea.producto.descripcion_es,
            image: linea.producto.imagen_del_producto,
          },
          quantity: parseInt(linea.unidades, 10),
          pvp: parseFloat(linea.pvp_unidad),
        })),
      };
    case INCREMENT_LINE_QUANTITY:
      return {
        ...state,
        lines: state.lines.map((line) =>
          line.id === action.payload.id
            ? { ...line, quantity: line.quantity + 1 }
            : line
        ),
      };
    case DECREMENT_LINE_QUANTITY:
      return {
        ...state,
        lines: state.lines.map((line) =>
          line.id === action.payload.id
            ? { ...line, quantity: line.quantity - 1 }
            : line
        ),
      };
    case UPDATE_LINE_QUANTITY:
      return {
        ...state,
        lines: state.lines.map((line) =>
          line.id === action.payload.id
            ? { ...line, quantity: action.payload.quantity }
            : line
        ),
        totales: action.payload.totales,
      };
    case UPDATE_DISCOUNT:
      return {
        ...state,
        promocion: action.payload.promocion,
        totales: action.payload.totales,
      };
    case DELETE_LINE:
      return {
        ...state,
        lines: state.lines.filter((line) => line.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const CartContext = createContext();
export const CartDispatchContext = createContext();

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onCompleted = useCallback(
    ({
      pedido: {
        id,
        id_pedido_estado,
        total_neto,
        total_bruto,
        total_iva,
        total_base_neto,
        total_descuento_neto,
        coste_envio_neto,
        descuento_aplicado,
        sitio,
        lineas_producto,
      },
    }) => {
      dispatch({
        type: LOAD_CART,
        payload: {
          id,
          id_pedido_estado,
          total_neto,
          total_bruto,
          total_iva,
          total_base_neto,
          total_descuento_neto,
          coste_envio_neto,
          descuento_aplicado,
          sitio,
          lineas_producto,
        },
      });
    },
    [dispatch]
  );

  const [loadCart] = useLazyQuery({
    url: "/pedidos/cesta/tmp",
    onCompleted,
  });

  useEffect(() => {
    const tmp = localStorage.getItem("tmp");
    if (tmp) {
      loadCart({}, `/${tmp}`);
    }
  }, [loadCart]);

  return (
    <CartContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within an CartProvider");
  }
  return context;
};

export const useCartDispatch = () => {
  const context = useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error("useCartDispatch must be used within an CartProvider");
  }
  return context;
};
