import { useEffect, useCallback } from "react";
import { useMutation } from "hooks";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

export default function AmazonCheckoutRoute() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const newOnCompleteCheckout = useCallback(
    ({ succes }) => {
      if (succes) {
        history.push("/pedido/fin");
      } else {
        history.push(`/pedido/error/${params.tmp}`);
      }
    },
    [params.tmp, history]
  );

  const onError = useCallback((error) => {
    console.log(error);
    history.push(`/pedido/error/${params.tmp}`);
  }, []);

  const [newcompleteCheckout] = useMutation({
    url: `/pedidos/confirm/amazon_pay/${params.tmp}`,
    onCompleted: newOnCompleteCheckout,
    onError,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const amazonCheckoutSessionId = queryParams.get("amazonCheckoutSessionId");
    newcompleteCheckout({ amazonCheckoutSessionId: amazonCheckoutSessionId });
  }, [params, location, newcompleteCheckout, history]);

  return (
    <div className="d-flex flex-1 flex-column flex-items-center flex-justify-center py-4">
      <div className="mb-3">
        <CircularProgress />
      </div>
      <p className="mb-0">Completando proceso de compra…</p>
    </div>
  );
}
