import { useState, useEffect, useMemo } from "react";
import queryString from "query-string";
import { useCallback } from "react";

const baseURL =
  process.env.REACT_APP_API_URL || "https://buy.bigbenespana.es/restapi/api";

const checkStatus = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location.reload();
  }
  const error = new Error(response.statusText);
  // @ts-ignore
  error.response = await response.json();
  throw error;
};

const parseJSON = (response) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};

const emptyObject = {};
const voidFunction = () => {};

/**
 * Fetch data from the server and handles authorization.
 * @param {Object} options - Query hook options
 * @param {String} [options.url] - Query endpoint added to API base URL
 * @param {Object} [options.options] - An object coinaining fetch options
 * @param {String} [options.options.method] - Method to use when fetching
 * @param {Object} [options.options.headers] - An object containing the call headers
 * @param {Object} [options.variables] - An object containing all of the variables your query requires to execute
 * @param {Boolean} [options.skip] - If true, the query is not executed.
 * @param {Function} [options.onCompleted] - A callback function that's called when your query successfully completes with no errors
 * @param {Function} [options.onError] - A callback function that's called when the query encounters errors
 * @returns {Object} A result object containing your query result plus some helpful fuctions for refetching and pagination
 */
export default function useQuery({
  url = "",
  options = emptyObject,
  variables = emptyObject,
  skip = false,
  onCompleted = voidFunction,
  onError = voidFunction,
}) {
  const [loading, setLoading] = useState(!skip);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(undefined);
  const token = useMemo(() => localStorage.getItem("token"), []);

  const fetchURL = useMemo(
    () =>
      queryString.stringifyUrl({
        url: `${baseURL}${url.startsWith("/") ? url : `/${url}`}`,
        query: variables,
      }),
    [url, variables]
  );

  const fetchOptions = useMemo(
    () => ({
      method: "GET",
      ...options,
      headers: {
        Accept: "application/json",
        ...options.headers,
        Authorization: token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    }),
    [options, token]
  );

  /**
   * A function that helps you fetch the next set of results for a paginated list field
   * @param {Object} variables - An object containing all of the variables your query requires to execute
   * @param {Function} updateQuery - A function to update the data with the previous value and the response
   */
  const fetchMore = async (refetchVariables = {}, updateQuery) => {
    if (typeof updateQuery !== "function") {
      throw new Error(
        "updateQuery function must be given when using fetchMore()"
      );
    }
    setLoading(true);
    setError(false);

    const refetchURL = queryString.stringifyUrl({
      url: `${process.env.REACT_APP_API_URL}${
        url.startsWith("/") ? url : `/${url}`
      }`,
      query: refetchVariables,
    });

    try {
      const response = await fetch(refetchURL, fetchOptions)
        .then(checkStatus)
        .then(parseJSON);

      setData((prev) => updateQuery(prev, response));
      setLoading(false);
      onCompleted(response);
    } catch (err) {
      const message = err.response ? err.response.message : err.message;
      setError(message);
      setLoading(false);
      onError(message);
    }
  };

  // A function that enables you to re-execute the query
  const refetch = async () => {
    setLoading(true);
    setError(undefined);
    setData(undefined);
    try {
      const response = await fetch(fetchURL, fetchOptions)
        .then(checkStatus)
        .then(parseJSON);

      setData(response);
      setLoading(false);
      onCompleted(response);
    } catch (err) {
      const message = err.response ? err.response.message : err.message;
      setError(message);
      setLoading(false);
      onError(message);
    }
  };

  const fetchData = useCallback(async () => {
    setData(undefined);
    setError(undefined);
    setLoading(true);
    try {
      const response = await fetch(fetchURL, fetchOptions)
        .then(checkStatus)
        .then(parseJSON);

      setData(response);
      setLoading(false);
      onCompleted(response);
    } catch (err) {
      const message = err.response ? err.response.message : err.message;
      setError(message);
      setLoading(false);
      onError(message);
    }
  }, [fetchURL, fetchOptions, onCompleted, onError]);

  useEffect(() => {
    if (skip === false) {
      fetchData();
    }
  }, [skip, fetchData]);

  return { data, loading, error, refetch, fetchMore };
}
