import React from "react";
import { Select, FormControl, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    width: "100%",
  },
  select: {
    padding: "12px 14px",
  },
}));
export default function SelectPaises({ handleSelectChange, state }) {
  const classes = useStyles();
  const handleChange = (event) => {
    const name = event.target.name;
    handleSelectChange(name, event.target.value);
  };
  return (
    <FormControl className={classes.formControl}>
      <Select
        placeholder="País"
        name="pais"
        defaultValue={"ES"}
        classes={{ selectMenu: classes.select }}
        value={state.pais}
        onChange={handleChange}
        variant="outlined"
      >
        <MenuItem value={"AF"}>Afganistán</MenuItem>
        <MenuItem value={"AL"}>Albania</MenuItem>
        <MenuItem value={"DE"}>Alemania</MenuItem>
        <MenuItem value={"AD"}>Andorra</MenuItem>
        <MenuItem value={"AO"}>Angola</MenuItem>
        <MenuItem value={"AI"}>Anguilla</MenuItem>
        <MenuItem value={"AQ"}>Antártida</MenuItem>
        <MenuItem value={"AG"}>Antigua y Barbuda</MenuItem>
        <MenuItem value={"AN"}>Antillas Holandesas</MenuItem>
        <MenuItem value={"SA"}>Arabia Saudí</MenuItem>
        <MenuItem value={"DZ"}>Argelia</MenuItem>
        <MenuItem value={"AR"}>Argentina</MenuItem>
        <MenuItem value={"AM"}>Armenia</MenuItem>
        <MenuItem value={"AW"}>Aruba</MenuItem>
        <MenuItem value={"AU"}>Australia</MenuItem>
        <MenuItem value={"AT"}>Austria</MenuItem>
        <MenuItem value={"AZ"}>Azerbaiyán</MenuItem>
        <MenuItem value={"BS"}>Bahamas</MenuItem>
        <MenuItem value={"BH"}>Bahrein</MenuItem>
        <MenuItem value={"BD"}>Bangladesh</MenuItem>
        <MenuItem value={"BB"}>Barbados</MenuItem>
        <MenuItem value={"BE"}>Bélgica</MenuItem>
        <MenuItem value={"BZ"}>Belice</MenuItem>
        <MenuItem value={"BJ"}>Benin</MenuItem>
        <MenuItem value={"BM"}>Bermudas</MenuItem>
        <MenuItem value={"BY"}>Bielorrusia</MenuItem>
        <MenuItem value={"MM"}>Birmania</MenuItem>
        <MenuItem value={"BO"}>Bolivia</MenuItem>
        <MenuItem value={"BA"}>Bosnia y Herzegovina</MenuItem>
        <MenuItem value={"BW"}>Botswana</MenuItem>
        <MenuItem value={"BR"}>Brasil</MenuItem>
        <MenuItem value={"BN"}>Brunei</MenuItem>
        <MenuItem value={"BG"}>Bulgaria</MenuItem>
        <MenuItem value={"BF"}>Burkina Faso</MenuItem>
        <MenuItem value={"BI"}>Burundi</MenuItem>
        <MenuItem value={"BT"}>Bután</MenuItem>
        <MenuItem value={"CV"}>Cabo Verde</MenuItem>
        <MenuItem value={"KH"}>Camboya</MenuItem>
        <MenuItem value={"CM"}>Camerún</MenuItem>
        <MenuItem value={"CA"}>Canadá</MenuItem>
        <MenuItem value={"TD"}>Chad</MenuItem>
        <MenuItem value={"CL"}>Chile</MenuItem>
        <MenuItem value={"CN"}>China</MenuItem>
        <MenuItem value={"CY"}>Chipre</MenuItem>
        <MenuItem value={"VA"}>Ciudad del Vaticano (Santa Sede)</MenuItem>
        <MenuItem value={"CO"}>Colombia</MenuItem>
        <MenuItem value={"KM"}>Comores</MenuItem>
        <MenuItem value={"CG"}>Congo</MenuItem>
        <MenuItem value={"CD"}>Congo, República Democrática del</MenuItem>
        <MenuItem value={"KR"}>Corea</MenuItem>
        <MenuItem value={"KP"}>Corea del Norte</MenuItem>
        <MenuItem value={"CI"}>Costa de Marfíl</MenuItem>
        <MenuItem value={"CR"}>Costa Rica</MenuItem>
        <MenuItem value={"HR"}>Croacia (Hrvatska)</MenuItem>
        <MenuItem value={"CU"}>Cuba</MenuItem>
        <MenuItem value={"DK"}>Dinamarca</MenuItem>
        <MenuItem value={"DJ"}>Djibouti</MenuItem>
        <MenuItem value={"DM"}>Dominica</MenuItem>
        <MenuItem value={"EC"}>Ecuador</MenuItem>
        <MenuItem value={"EG"}>Egipto</MenuItem>
        <MenuItem value={"SV"}>El Salvador</MenuItem>
        <MenuItem value={"AE"}>Emiratos Árabes Unidos</MenuItem>
        <MenuItem value={"ER"}>Eritrea</MenuItem>
        <MenuItem value={"SI"}>Eslovenia</MenuItem>
        <MenuItem value={"ES"}>España</MenuItem>
        <MenuItem value={"US"}>Estados Unidos</MenuItem>
        <MenuItem value={"EE"}>Estonia</MenuItem>
        <MenuItem value={"ET"}>Etiopía</MenuItem>
        <MenuItem value={"FJ"}>Fiji</MenuItem>
        <MenuItem value={"PH"}>Filipinas</MenuItem>
        <MenuItem value={"FI"}>Finlandia</MenuItem>
        <MenuItem value={"FR"}>Francia</MenuItem>
        <MenuItem value={"GA"}>Gabón</MenuItem>
        <MenuItem value={"GM"}>Gambia</MenuItem>
        <MenuItem value={"GE"}>Georgia</MenuItem>
        <MenuItem value={"GH"}>Ghana</MenuItem>
        <MenuItem value={"GI"}>Gibraltar</MenuItem>
        <MenuItem value={"GD"}>Granada</MenuItem>
        <MenuItem value={"GR"}>Grecia</MenuItem>
        <MenuItem value={"GL"}>Groenlandia</MenuItem>
        <MenuItem value={"GP"}>Guadalupe</MenuItem>
        <MenuItem value={"GU"}>Guam</MenuItem>
        <MenuItem value={"GT"}>Guatemala</MenuItem>
        <MenuItem value={"GY"}>Guayana</MenuItem>
        <MenuItem value={"GF"}>Guayana Francesa</MenuItem>
        <MenuItem value={"GN"}>Guinea</MenuItem>
        <MenuItem value={"GQ"}>Guinea Ecuatorial</MenuItem>
        <MenuItem value={"GW"}>Guinea-Bissau</MenuItem>
        <MenuItem value={"HT"}>Haití</MenuItem>
        <MenuItem value={"HN"}>Honduras</MenuItem>
        <MenuItem value={"HU"}>Hungría</MenuItem>
        <MenuItem value={"IN"}>India</MenuItem>
        <MenuItem value={"ID"}>Indonesia</MenuItem>
        <MenuItem value={"IQ"}>Irak</MenuItem>
        <MenuItem value={"IR"}>Irán</MenuItem>
        <MenuItem value={"IE"}>Irlanda</MenuItem>
        <MenuItem value={"BV"}>Isla Bouvet</MenuItem>
        <MenuItem value={"CX"}>Isla de Christmas</MenuItem>
        <MenuItem value={"IS"}>Islandia</MenuItem>
        <MenuItem value={"KY"}>Islas Caimán</MenuItem>
        <MenuItem value={"CK"}>Islas Cook</MenuItem>
        <MenuItem value={"CC"}>Islas de Cocos o Keeling</MenuItem>
        <MenuItem value={"FO"}>Islas Faroe</MenuItem>
        <MenuItem value={"HM"}>Islas Heard y McDonald</MenuItem>
        <MenuItem value={"FK"}>Islas Malvinas</MenuItem>
        <MenuItem value={"MP"}>Islas Marianas del Norte</MenuItem>
        <MenuItem value={"MH"}>Islas Marshall</MenuItem>
        <MenuItem value={"UM"}>Islas menores de Estados Unidos</MenuItem>
        <MenuItem value={"PW"}>Islas Palau</MenuItem>
        <MenuItem value={"SB"}>Islas Salomón</MenuItem>
        <MenuItem value={"SJ"}>Islas Svalbard y Jan Mayen</MenuItem>
        <MenuItem value={"TK"}>Islas Tokelau</MenuItem>
        <MenuItem value={"TC"}>Islas Turks y Caicos</MenuItem>
        <MenuItem value={"VI"}>Islas Vírgenes (EEUU)</MenuItem>
        <MenuItem value={"VG"}>Islas Vírgenes (Reino Unido)</MenuItem>
        <MenuItem value={"WF"}>Islas Wallis y Futuna</MenuItem>
        <MenuItem value={"IL"}>Israel</MenuItem>
        <MenuItem value={"IT"}>Italia</MenuItem>
        <MenuItem value={"JM"}>Jamaica</MenuItem>
        <MenuItem value={"JP"}>Japón</MenuItem>
        <MenuItem value={"JO"}>Jordania</MenuItem>
        <MenuItem value={"KZ"}>Kazajistán</MenuItem>
        <MenuItem value={"KE"}>Kenia</MenuItem>
        <MenuItem value={"KG"}>Kirguizistán</MenuItem>
        <MenuItem value={"KI"}>Kiribati</MenuItem>
        <MenuItem value={"KW"}>Kuwait</MenuItem>
        <MenuItem value={"LA"}>Laos</MenuItem>
        <MenuItem value={"LS"}>Lesotho</MenuItem>
        <MenuItem value={"LV"}>Letonia</MenuItem>
        <MenuItem value={"LB"}>Líbano</MenuItem>
        <MenuItem value={"LR"}>Liberia</MenuItem>
        <MenuItem value={"LY"}>Libia</MenuItem>
        <MenuItem value={"LI"}>Liechtenstein</MenuItem>
        <MenuItem value={"LT"}>Lituania</MenuItem>
        <MenuItem value={"LU"}>Luxemburgo</MenuItem>
        <MenuItem value={"MK"}>Macedonia, Ex-República Yugoslava de</MenuItem>
        <MenuItem value={"MG"}>Madagascar</MenuItem>
        <MenuItem value={"MY"}>Malasia</MenuItem>
        <MenuItem value={"MW"}>Malawi</MenuItem>
        <MenuItem value={"MV"}>Maldivas</MenuItem>
        <MenuItem value={"ML"}>Malí</MenuItem>
        <MenuItem value={"MT"}>Malta</MenuItem>
        <MenuItem value={"MA"}>Marruecos</MenuItem>
        <MenuItem value={"MQ"}>Martinica</MenuItem>
        <MenuItem value={"MU"}>Mauricio</MenuItem>
        <MenuItem value={"MR"}>Mauritania</MenuItem>
        <MenuItem value={"YT"}>Mayotte</MenuItem>
        <MenuItem value={"MX"}>México</MenuItem>
        <MenuItem value={"FM"}>Micronesia</MenuItem>
        <MenuItem value={"MD"}>Moldavia</MenuItem>
        <MenuItem value={"MC"}>Mónaco</MenuItem>
        <MenuItem value={"MN"}>Mongolia</MenuItem>
        <MenuItem value={"MS"}>Montserrat</MenuItem>
        <MenuItem value={"MZ"}>Mozambique</MenuItem>
        <MenuItem value={"NA"}>Namibia</MenuItem>
        <MenuItem value={"NR"}>Nauru</MenuItem>
        <MenuItem value={"NP"}>Nepal</MenuItem>
        <MenuItem value={"NI"}>Nicaragua</MenuItem>
        <MenuItem value={"NE"}>Níger</MenuItem>
        <MenuItem value={"NG"}>Nigeria</MenuItem>
        <MenuItem value={"NU"}>Niue</MenuItem>
        <MenuItem value={"NF"}>Norfolk</MenuItem>
        <MenuItem value={"NO"}>Noruega</MenuItem>
        <MenuItem value={"NC"}>Nueva Caledonia</MenuItem>
        <MenuItem value={"NZ"}>Nueva Zelanda</MenuItem>
        <MenuItem value={"OM"}>Omán</MenuItem>
        <MenuItem value={"NL"}>Países Bajos</MenuItem>
        <MenuItem value={"PA"}>Panamá</MenuItem>
        <MenuItem value={"PG"}>Papúa Nueva Guinea</MenuItem>
        <MenuItem value={"PK"}>Paquistán</MenuItem>
        <MenuItem value={"PY"}>Paraguay</MenuItem>
        <MenuItem value={"PE"}>Perú</MenuItem>
        <MenuItem value={"PN"}>Pitcairn</MenuItem>
        <MenuItem value={"PF"}>Polinesia Francesa</MenuItem>
        <MenuItem value={"PL"}>Polonia</MenuItem>
        <MenuItem value={"PT"}>Portugal</MenuItem>
        <MenuItem value={"PR"}>Puerto Rico</MenuItem>
        <MenuItem value={"QA"}>Qatar</MenuItem>
        <MenuItem value={"UK"}>Reino Unido</MenuItem>
        <MenuItem value={"CF"}>República Centroafricana</MenuItem>
        <MenuItem value={"CZ"}>República Checa</MenuItem>
        <MenuItem value={"ZA"}>República de Sudáfrica</MenuItem>
        <MenuItem value={"DO"}>República Dominicana</MenuItem>
        <MenuItem value={"SK"}>República Eslovaca</MenuItem>
        <MenuItem value={"RE"}>Reunión</MenuItem>
        <MenuItem value={"RW"}>Ruanda</MenuItem>
        <MenuItem value={"RO"}>Rumania</MenuItem>
        <MenuItem value={"RU"}>Rusia</MenuItem>
        <MenuItem value={"EH"}>Sahara Occidental</MenuItem>
        <MenuItem value={"KN"}>Saint Kitts y Nevis</MenuItem>
        <MenuItem value={"WS"}>Samoa</MenuItem>
        <MenuItem value={"AS"}>Samoa Americana</MenuItem>
        <MenuItem value={"SM"}>San Marino</MenuItem>
        <MenuItem value={"VC"}>San Vicente y Granadinas</MenuItem>
        <MenuItem value={"SH"}>Santa Helena</MenuItem>
        <MenuItem value={"LC"}>Santa Lucía</MenuItem>
        <MenuItem value={"ST"}>Santo Tomé y Príncipe</MenuItem>
        <MenuItem value={"SN"}>Senegal</MenuItem>
        <MenuItem value={"SC"}>Seychelles</MenuItem>
        <MenuItem value={"SL"}>Sierra Leona</MenuItem>
        <MenuItem value={"SG"}>Singapur</MenuItem>
        <MenuItem value={"SY"}>Siria</MenuItem>
        <MenuItem value={"SO"}>Somalia</MenuItem>
        <MenuItem value={"LK"}>Sri Lanka</MenuItem>
        <MenuItem value={"PM"}>St Pierre y Miquelon</MenuItem>
        <MenuItem value={"SZ"}>Suazilandia</MenuItem>
        <MenuItem value={"SD"}>Sudán</MenuItem>
        <MenuItem value={"SE"}>Suecia</MenuItem>
        <MenuItem value={"CH"}>Suiza</MenuItem>
        <MenuItem value={"SR"}>Surinam</MenuItem>
        <MenuItem value={"TH"}>Tailandia</MenuItem>
        <MenuItem value={"TW"}>Taiwán</MenuItem>
        <MenuItem value={"TZ"}>Tanzania</MenuItem>
        <MenuItem value={"TJ"}>Tayikistán</MenuItem>
        <MenuItem value={"TF"}>Territorios franceses del Sur</MenuItem>
        <MenuItem value={"TP"}>Timor Oriental</MenuItem>
        <MenuItem value={"TG"}>Togo</MenuItem>
        <MenuItem value={"TO"}>Tonga</MenuItem>
        <MenuItem value={"TT"}>Trinidad y Tobago</MenuItem>
        <MenuItem value={"TN"}>Túnez</MenuItem>
        <MenuItem value={"TM"}>Turkmenistán</MenuItem>
        <MenuItem value={"TR"}>Turquía</MenuItem>
        <MenuItem value={"TV"}>Tuvalu</MenuItem>
        <MenuItem value={"UA"}>Ucrania</MenuItem>
        <MenuItem value={"UG"}>Uganda</MenuItem>
        <MenuItem value={"UY"}>Uruguay</MenuItem>
        <MenuItem value={"UZ"}>Uzbekistán</MenuItem>
        <MenuItem value={"VU"}>Vanuatu</MenuItem>
        <MenuItem value={"VE"}>Venezuela</MenuItem>
        <MenuItem value={"VN"}>Vietnam</MenuItem>
        <MenuItem value={"YE"}>Yemen</MenuItem>
        <MenuItem value={"YU"}>Yugoslavia</MenuItem>
        <MenuItem value={"ZM"}>Zambia</MenuItem>
        <MenuItem value={"ZW"}>Zimbabue</MenuItem>
      </Select>
    </FormControl>
  );
}
