import React, { useState, useCallback } from "react";
import { useCart } from "context/cart.context";
import { useMutation } from "hooks";
import { Link } from "react-router-dom";
import SelectPaises from "components/SelectPaises";
import {
  Button,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormHelperText,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowLeftIcon } from "@primer/octicons-react";
import Alert from "@mui/material/Alert";
import FadeIn from "react-fade-in";
import { RedsysForm, PaypalForm } from "components/PaymentsForms";
import GooglePayButton from "@google-pay/button-react";
import { AmazonPayButton, AuthModal, AddressModal } from "components";
import { useHistory } from "react-router-dom";
import { useAccount } from "context/account.context";

const useStyles = makeStyles((theme) => ({
  inputs: {
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  boton: {
    minWidth: "100px",
    fontSize: "90%",
    height: "40px",
  },
}));

export default function ViewPedido() {
  const classes = useStyles();
  const cart = useCart();
  const account = useAccount();
  const history = useHistory();
  const [openAuth, setOpenAuth] = useState(false);
  const [buyError, setBuyError] = useState("");
  const [fields, setFields] = useState([
    {
      field: "email",
      value: account.isLoggedIn ? account.user.email : "",
      error: false,
      message: "",
    },

    { field: "shippingName", value: "", error: false, message: "" },
    { field: "shippingLastName", value: "", error: false, message: "" },
    { field: "shippingAddressLine", value: "", error: false, message: "" },
    { field: "shippingApparmentLine", value: "", error: false, message: "" },
    { field: "shippingPhone", value: "", error: false, message: "" },
    { field: "shippingPostalCode", value: "", error: false, message: "" },
    { field: "shippingCity", value: "", error: false, message: "" },
    { field: "shippingTerritory", value: "", error: false, message: "" },

    { field: "billingNIF", value: "", error: false, message: "" },
    { field: "billingName", value: "", error: false, message: "" },
    { field: "billingLastName", value: "", error: false, message: "" },
    { field: "billingAddressLine", value: "", error: false, message: "" },
    { field: "billingApparmentLine", value: "", error: false, message: "" },
    { field: "billingPhone", value: "", error: false, message: "" },
    { field: "billingPostalCode", value: "", error: false, message: "" },
    { field: "billingCity", value: "", error: false, message: "" },
    { field: "billingTerritory", value: "", error: false, message: "" },
  ]);

  const [redsysData, setRedsysData] = useState({ url_Tpv: "" });
  const [paypalData, setPaypalData] = useState({ paypal_url: "" });

  const [shippingCountry, setShippingCountry] = useState("ES");
  const [billingCountry, setBillingCountry] = useState("ES");
  const [tipoPago, setTipoPago] = useState(null);
  const [checked, setChecked] = useState(true);
  const [isSendingGoogle, setIsSendingGoogle] = useState(false);
  const [errorRadio, setErrorRadio] = useState(false);
  const [errorRadioMessage, setErrorRadioMessage] = useState("");
  const [saveAddress, setSaveAddress] = useState(false);
  const [shippingAddressModalOpen, setShippingAddressModalOpen] =
    useState(false);
  const [billingAddressModalOpen, setBillingAddressModalOpen] = useState(false);

  const onChangeCheckbox = (event) => {
    setChecked(event.target.checked);
    //formData.intercompany = event.target.checked;
  };

  // Parse redsys form received from server
  const onPurchaseSuccess = useCallback((response) => {
    switch (response.forma_pago) {
      case "google_pay":
      case "redsys":
        setRedsysData(response.formData);
        break;
      case "paypal":
        setPaypalData(response.formData);
        break;

      default:
    }

    return response;
  }, []);

  const manageBuyError = (err) => {
    setIsSendingGoogle(false);
    if (err.fields) {
      setFields(err.fields);
    }

    err.buy_error ? setBuyError(err.buy_error) : setBuyError("");
  };

  const [purchase, { loading }] = useMutation({
    url: "/pedidos/buy",
    onCompleted: onPurchaseSuccess,
    onError: manageBuyError,
  });

  if (cart.id === undefined) return null;

  if ([3, 4, 5, 6, 10].includes(cart.id_pedido_estado)) {
    //si el pedido esta completado llevo a fin
    history.push("/pedido/fin");
  }

  if ([7, 8, 9, 11].includes(cart.id_pedido_estado)) {
    //si el pedido esta en un estado erroneo, envio a error
    history.push("/pedido/error/" + cart.tmp);
  }

  const handleTipoPago = (event) => {
    setTipoPago(event.target.value);
    setErrorRadioMessage(" ");
    setErrorRadio(false);
  };

  const handlePurchase = (data) => {
    if (!tipoPago) {
      setErrorRadioMessage("Seleccione una opción de pago.");
      setErrorRadio(true);
    } else {
      purchase({
        ...data,
        saveAddress,
        id_user: account.isLoggedIn ? account.user.id : undefined,
      });
    }
  };

  const getField = (field) => {
    let valor = "";
    fields.forEach((item) => {
      if (item.field === field) {
        valor = item.value;
      }
    });
    return valor;
  };

  const getFieldError = (field) => {
    let valor = false;
    fields.forEach((item) => {
      if (item.field === field) {
        valor = item.error;
      }
    });
    return valor;
  };

  const getFieldErrorMessage = (field) => {
    let valor = "";
    fields.forEach((item) => {
      if (item.field === field) {
        valor = item.message;
      }
    });
    return valor;
  };

  const setField = (val, field, error = false, message = "") => {
    setFields((prev) =>
      prev.map((item, index) => {
        if (item.field === field) {
          return { ...item, value: val, error: error, message: message };
        }
        return item;
      })
    );
  };

  return (
    <div className="d-md-flex flex-1 color-bg-primary">
      <div className="col-md-6 d-flex flex-column flex-md-items-end flex-justify-start p-responsive py-4 p-md-6">
        <div style={{ maxWidth: 572 }}>
          <div>
            <div className="d-flex flex-items-end flex-justify-between mb-3">
              <div className="f4">Información de contacto</div>
              {!account.isLoggedIn && (
                <>
                  <div className="color-text-secondary">
                    ¿Ya tienes una cuenta?{" "}
                    <button
                      className="Link no-underline btn-link"
                      onClick={() => setOpenAuth(true)}
                    >
                      Iniciar sesión
                    </button>
                  </div>
                  <AuthModal
                    open={openAuth}
                    onClose={() => setOpenAuth(false)}
                  />
                </>
              )}
            </div>
            <TextField
              className={classes.inputs}
              size="small"
              label="Email"
              type="email"
              name="email"
              id="email"
              value={getField("email")}
              onChange={({ target }) => {
                setField(target.value, "email");
              }}
              error={getFieldError("email")}
              helperText={getFieldErrorMessage("email")}
              variant="outlined"
            />
          </div>
          <div className="mt-4">
            <div className="d-flex flex-items-end flex-justify-between mb-3">
              <p className="f4 mb-0">Dirección de envío</p>
              {account.isLoggedIn && account.user?.direcciones?.length > 0 && (
                <>
                  <div className="color-text-secondary">
                    <button
                      className="Link no-underline btn-link"
                      onClick={() => setShippingAddressModalOpen(true)}
                    >
                      Importar dirección de envío
                    </button>
                  </div>
                  <AddressModal
                    open={shippingAddressModalOpen}
                    onClose={() => setShippingAddressModalOpen(false)}
                    onSelect={(address) => {
                      setField(address.nombre || "", "shippingName");
                      setField(address.apellidos || "", "shippingLastName");
                      setField(address.direccion || "", "shippingAddressLine");
                      setField(
                        address.direccion2 || "",
                        "shippingApparmentLine"
                      );
                      setField(address.cp || "", "shippingPostalCode");
                      setField(address.localidad || "", "shippingCity");
                      setField(address.provincia || "", "shippingTerritory");
                      setShippingCountry(address.pais);
                      setField(address.telefono || "", "shippingPhone");
                      setShippingAddressModalOpen(false);
                    }}
                  />
                </>
              )}
            </div>
            <div className="d-md-flex gutter-condensed">
              <div className="col-md-6 mb-3">
                <TextField
                  className={classes.inputs}
                  type="text"
                  size="small"
                  label="Nombre"
                  name="shippingName"
                  id="shippingName"
                  value={getField("shippingName")}
                  onChange={({ target }) => {
                    setField(target.value, "shippingName");
                  }}
                  error={getFieldError("shippingName")}
                  helperText={getFieldErrorMessage("shippingName")}
                  variant="outlined"
                />
              </div>
              <div className="col-md-6 mb-3">
                <TextField
                  className={classes.inputs}
                  type="text"
                  size="small"
                  label="Apellidos"
                  name="shippingLastName"
                  id="shippingLastName"
                  value={getField("shippingLastName")}
                  onChange={({ target }) => {
                    setField(target.value, "shippingLastName");
                  }}
                  error={getFieldError("shippingLastName")}
                  helperText={getFieldErrorMessage("shippingLastName")}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="mb-3">
              <TextField
                className={classes.inputs}
                type="text"
                size="small"
                label="Calle y número de casa"
                name="shippingAddressLine"
                id="shippingAddressLine"
                value={getField("shippingAddressLine")}
                onChange={({ target }) => {
                  setField(target.value, "shippingAddressLine");
                }}
                error={getFieldError("shippingAddressLine")}
                helperText={getFieldErrorMessage("shippingAddressLine")}
                variant="outlined"
              />
            </div>
            <div className="mb-3">
              <TextField
                className={classes.inputs}
                type="text"
                size="small"
                label="Apartamento, local, etc. (opcional)"
                name="shippingApparmentLine"
                id="shippingApparmentLine"
                value={getField("shippingApparmentLine")}
                onChange={({ target }) => {
                  setField(target.value, "shippingApparmentLine");
                }}
                error={getFieldError("shippingApparmentLine")}
                helperText={getFieldErrorMessage("shippingApparmentLine")}
                variant="outlined"
              />
            </div>
            <div className="mb-3">
              <TextField
                className={classes.inputs}
                type="text"
                size="small"
                label="Teléfono"
                name="shippingPhone"
                id="shippingPhone"
                value={getField("shippingPhone")}
                onChange={({ target }) => {
                  setField(target.value, "shippingPhone");
                }}
                error={getFieldError("shippingPhone")}
                helperText={getFieldErrorMessage("shippingPhone")}
                variant="outlined"
              />
            </div>
            <div className="d-md-flex gutter-condensed">
              <div className="col-md-4 mb-3">
                <TextField
                  className={classes.inputs}
                  type="text"
                  size="small"
                  label="Código postal"
                  name="shippingPostalCode"
                  id="shippingPostalCode"
                  value={getField("shippingPostalCode")}
                  onChange={({ target }) => {
                    setField(target.value, "shippingPostalCode");
                  }}
                  error={getFieldError("shippingPostalCode")}
                  helperText={getFieldErrorMessage("shippingPostalCode")}
                  variant="outlined"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextField
                  className={classes.inputs}
                  type="text"
                  size="small"
                  label="Ciudad"
                  name="shippingCity"
                  id="shippingCity"
                  value={getField("shippingCity")}
                  onChange={({ target }) => {
                    setField(target.value, "shippingCity");
                  }}
                  error={getFieldError("shippingCity")}
                  helperText={getFieldErrorMessage("shippingCity")}
                  variant="outlined"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextField
                  className={classes.inputs}
                  type="text"
                  size="small"
                  label="Provincia"
                  name="shippingTerritory"
                  id="shippingTerritory"
                  value={getField("shippingTerritory")}
                  onChange={({ target }) => {
                    setField(target.value, "shippingTerritory");
                  }}
                  error={getFieldError("shippingTerritory")}
                  helperText={getFieldErrorMessage("shippingTerritory")}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="mb-3">
              <SelectPaises
                handleSelectChange={setShippingCountry}
                state={shippingCountry}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={onChangeCheckbox}
                  name="check_facturacion"
                  color="primary"
                />
              }
              label="La dirección de envío es igual que la de facturación."
            />
            {!checked && (
              <FadeIn transitionDuration={1000} delay={50}>
                <div className="mt-4">
                  <div className="d-flex flex-items-end flex-justify-between mb-3">
                    <p className="f4 mb-0">Dirección de facturación</p>
                    {account.isLoggedIn &&
                      account.user?.direcciones?.length > 0 && (
                        <>
                          <div className="color-text-secondary">
                            <button
                              className="Link no-underline btn-link"
                              onClick={() => setBillingAddressModalOpen(true)}
                            >
                              Importar dirección de facturación
                            </button>
                          </div>
                          <AddressModal
                            open={billingAddressModalOpen}
                            onClose={() => setBillingAddressModalOpen(false)}
                            onSelect={(address) => {
                              setField(address.nombre || "", "billingName");
                              setField(
                                address.apellidos || "",
                                "billingLastName"
                              );
                              setField(
                                address.direccion || "",
                                "billingAddressLine"
                              );
                              setField(
                                address.direccion2 || "",
                                "billingApparmentLine"
                              );
                              setField(address.cp || "", "billingPostalCode");
                              setField(address.localidad || "", "billingCity");
                              setField(
                                address.provincia || "",
                                "billingTerritory"
                              );
                              setBillingCountry(address.pais);
                              setField(address.telefono || "", "billingPhone");
                              setBillingAddressModalOpen(false);
                            }}
                          />
                        </>
                      )}
                  </div>
                  <div className="mb-3">
                    <TextField
                      className={classes.inputs}
                      type="text"
                      size="small"
                      label="NIF / CIF"
                      name="billingNIF"
                      id="billingNIF"
                      value={getField("billingNIF")}
                      onChange={({ target }) => {
                        setField(target.value, "billingNIF");
                      }}
                      error={getFieldError("billingNIF")}
                      helperText={getFieldErrorMessage("billingNIF")}
                      variant="outlined"
                    />
                  </div>
                  <div className="d-md-flex gutter-condensed">
                    <div className="col-md-6 mb-3">
                      <TextField
                        className={classes.inputs}
                        type="text"
                        size="small"
                        label="Nombre"
                        name="billingName"
                        id="billingName"
                        value={getField("billingName")}
                        onChange={({ target }) => {
                          setField(target.value, "billingName");
                        }}
                        error={getFieldError("billingName")}
                        helperText={getFieldErrorMessage("billingName")}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <TextField
                        className={classes.inputs}
                        type="text"
                        size="small"
                        label="Apellidos"
                        name="billingLastName"
                        id="billingLastName"
                        value={getField("billingLastName")}
                        onChange={({ target }) => {
                          setField(target.value, "billingLastName");
                        }}
                        error={getFieldError("billingLastName")}
                        helperText={getFieldErrorMessage("billingLastName")}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <TextField
                      className={classes.inputs}
                      type="text"
                      size="small"
                      label="Calle y número de casa"
                      name="billingAddressLine"
                      id="billingAddressLine"
                      value={getField("billingAddressLine")}
                      onChange={({ target }) => {
                        setField(target.value, "billingAddressLine");
                      }}
                      error={getFieldError("billingAddressLine")}
                      helperText={getFieldErrorMessage("billingAddressLine")}
                      variant="outlined"
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      className={classes.inputs}
                      type="text"
                      size="small"
                      label="Apartamento, local, etc. (opcional)"
                      name="billingApparmentLine"
                      id="billingApparmentLine"
                      value={getField("billingApparmentLine")}
                      onChange={({ target }) => {
                        setField(target.value, "billingApparmentLine");
                      }}
                      error={getFieldError("billingApparmentLine")}
                      helperText={getFieldErrorMessage("billingApparmentLine")}
                      variant="outlined"
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      className={classes.inputs}
                      type="text"
                      size="small"
                      label="Teléfono"
                      name="billingPhone"
                      id="billingPhone"
                      value={getField("billingPhone")}
                      onChange={({ target }) => {
                        setField(target.value, "billingPhone");
                      }}
                      error={getFieldError("billingPhone")}
                      helperText={getFieldErrorMessage("billingPhone")}
                      variant="outlined"
                    />
                  </div>
                  <div className="d-md-flex gutter-condensed">
                    <div className="col-md-4 mb-3">
                      <TextField
                        className={classes.inputs}
                        type="text"
                        size="small"
                        label="Código postal"
                        name="billingPostalCode"
                        id="billingPostalCode"
                        value={getField("billingPostalCode")}
                        onChange={({ target }) => {
                          setField(target.value, "billingPostalCode");
                        }}
                        error={getFieldError("billingPostalCode")}
                        helperText={getFieldErrorMessage("billingPostalCode")}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <TextField
                        className={classes.inputs}
                        type="text"
                        size="small"
                        label="Ciudad"
                        name="billingCity"
                        id="billingCity"
                        value={getField("billingCity")}
                        onChange={({ target }) => {
                          setField(target.value, "billingCity");
                        }}
                        error={getFieldError("billingCity")}
                        helperText={getFieldErrorMessage("billingCity")}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <TextField
                        className={classes.inputs}
                        type="text"
                        size="small"
                        label="Provincia"
                        name="billingTerritory"
                        id="billingTerritory"
                        value={getField("billingTerritory")}
                        onChange={({ target }) => {
                          setField(target.value, "billingTerritory");
                        }}
                        error={getFieldError("billingTerritory")}
                        helperText={getFieldErrorMessage("billingTerritory")}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <SelectPaises
                      handleSelectChange={setBillingCountry}
                      state={billingCountry}
                    />
                  </div>
                </div>
              </FadeIn>
            )}
            {account.isLoggedIn && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveAddress}
                    onChange={({ target }) => setSaveAddress(target.checked)}
                    name="check-save-address"
                    color="primary"
                  />
                }
                label="Guardar mi información y consultar más rápidamente la próxima vez"
              />
            )}
            <FormControl error={errorRadio} color="primary">
              <RadioGroup
                size="small"
                aria-label="gender"
                name="tipoPago"
                className={classes.margin}
                onChange={handleTipoPago}
                error={errorRadio.toString()}
              >
                <Grid item>
                  <FormControlLabel
                    value="redsys"
                    control={<Radio color="primary" />}
                    label="Tarjeta de crédito"
                  />
                  <img
                    width="40px"
                    src="/img/visa.png"
                    alt="Visa"
                    draggable={false}
                    style={{
                      position: "relative",
                      top: 6,
                    }}
                  />
                  <img
                    width="40px"
                    src="/img/mastercard.jpg"
                    alt="Mastercard"
                    draggable={false}
                    style={{
                      position: "relative",
                      top: 6,
                    }}
                  />
                  <img
                    width="29px"
                    src="/img/maestro.png"
                    alt="Maestro"
                    draggable={false}
                    style={{
                      position: "relative",
                      top: 6,
                    }}
                  />
                  <img
                    width="40px"
                    src="/img/american.png"
                    alt="American Express"
                    draggable={false}
                    style={{
                      position: "relative",
                      top: 6,
                    }}
                  />
                  <img
                    width="40px"
                    src="/img/diners.png"
                    alt="Diners Club International"
                    draggable={false}
                    style={{
                      position: "relative",
                      top: 6,
                    }}
                  />
                  <img
                    width="40px"
                    src="/img/discover.png"
                    alt="Discover"
                    draggable={false}
                    style={{
                      position: "relative",
                      top: 6,
                    }}
                  />
                  <img
                    width="40px"
                    src="/img/unionpay.png"
                    alt="Discover"
                    draggable={false}
                    style={{
                      paddingLeft: 5,
                      position: "relative",
                      top: 6,
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value="paypal"
                    control={<Radio color="primary" />}
                    label="PayPal"
                  />
                  <img
                    width="70px"
                    src="/img/paypal.png"
                    alt="PayPal"
                    style={{
                      position: "relative",
                      top: 6,
                    }}
                  />
                </Grid>

                {/* AMAZON PROD */}
                {cart.site.amazon_public_key_id && !cart.site.amazon_sandbox && (
                  <Grid item>
                    <FormControlLabel
                      value="amazon_pay"
                      control={<Radio color="primary" />}
                      label="Amazon Pay"
                    />
                    <img
                      width="70px"
                      src="/img/amazonpay.svg"
                      alt="Amazon Pay"
                      style={{
                        position: "relative",
                        top: 5,
                      }}
                    />
                  </Grid>
                )}

                {/* AMAZON SANDBOX */}
                {process.env.REACT_APP_ENVIRONMENT != "prod" &&
                  cart.site.amazon_sandbox &&
                  cart.site.amazon_sandbox_public_key_id && (
                    <Grid item>
                      <FormControlLabel
                        value="amazon_pay_sandbox"
                        control={<Radio color="primary" />}
                        label="Amazon Pay SANDBOX"
                      />
                      <img
                        width="70px"
                        src="/img/amazonpay.svg"
                        alt="Amazon Pay"
                        style={{
                          position: "relative",
                          top: 5,
                        }}
                      />
                    </Grid>
                  )}

                {/*
                <Grid item>
                  <FormControlLabel
                    value="apple_pay"
                    control={<Radio color="primary" />}
                    label="Apple Pay"
                  />
                  <img
                    width="43px"
                    src="/img/applepay.png"
                    alt="Apple Pay"
                    style={{
                      position: "relative",
                      top: 5,
                    }}
                  />
                </Grid>
                */}
                {/* <Grid item>
                  <FormControlLabel
                    value="google_pay"
                    control={<Radio color="primary" />}
                    label="Google Pay"
                  />
                  <img
                    width="50px"
                    src="/img/googlepay.png"
                    alt="Google Pay"
                    style={{
                      position: "relative",
                      top: 7,
                    }}
                  />
                </Grid> */}
              </RadioGroup>
              <FormHelperText>{errorRadioMessage}</FormHelperText>
            </FormControl>
            <br />

            {tipoPago === "google_pay" && (
              <div>
                {!isSendingGoogle ? (
                  <GooglePayButton
                    environment="TEST"
                    buttonColor="black"
                    buttonLocale="es"
                    buttonType="pay"
                    paymentRequest={{
                      apiVersion: 2,
                      apiVersionMinor: 0,
                      allowedPaymentMethods: [
                        {
                          type: "CARD",
                          parameters: {
                            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                            allowedCardNetworks: [
                              "AMEX",
                              "DISCOVER",
                              "JCB",
                              "MASTERCARD",
                              "VISA",
                            ],
                          },
                          tokenizationSpecification: {
                            type: "PAYMENT_GATEWAY",
                            parameters: {
                              gateway: "redsys",
                              gatewayMerchantId: cart.site.tpv_merchant_code,
                            },
                          },
                        },
                      ],
                      merchantInfo: {
                        merchantId: "BCR2DN6TY6EO3X2B",
                        merchantName: "Sitelicon Ecommerce Services",
                        merchantOrigin: "sitelicon.com",
                      },
                      transactionInfo: {
                        totalPriceStatus: "FINAL",
                        totalPriceLabel: "Total",
                        totalPrice: cart.totales.total_neto.toFixed(2),
                        currencyCode: "EUR",
                        countryCode: "ES",
                      },
                    }}
                    onLoadPaymentData={(paymentRequest) => {
                      handlePurchase({
                        id_pedido: cart.id,
                        forma_pago: tipoPago,
                        token_google_pay: JSON.parse(
                          paymentRequest.paymentMethodData.tokenizationData
                            .token
                        ).signature,
                        fields: fields,
                        shippingCountry: shippingCountry,
                        billingCountry: billingCountry,
                        facturacion: checked,
                        cart: cart,
                      });
                    }}
                    onError={(error) => {
                      setIsSendingGoogle(false);
                      console.log(error);
                    }}
                    onClick={() => {
                      setIsSendingGoogle(true);
                    }}
                  />
                ) : (
                  <CircularProgress
                    style={{ margin: "20px" }}
                    color="inherit"
                  />
                )}
              </div>
            )}

            {/* AMAZON PROD */}
            {tipoPago === "amazon_pay" &&
              cart.site.amazon_public_key_id &&
              !cart.site.amazon_sandbox && (
                <AmazonPayButton
                  formFields={fields}
                  shippingCountry={shippingCountry}
                  billingCountry={billingCountry}
                  facturacion={checked}
                  setFormFields={setFields}
                  saveAddress={saveAddress}
                  merchantId={cart.site.amazon_merchant_id}
                  publicKeyId={cart.site.amazon_public_key_id}
                />
              )}

            {/* AMAZON SANDBOX */}
            {tipoPago === "amazon_pay_sandbox" &&
              cart.site.amazon_sandbox &&
              cart.site.amazon_sandbox_public_key_id && (
                <AmazonPayButton
                  formFields={fields}
                  shippingCountry={shippingCountry}
                  billingCountry={billingCountry}
                  facturacion={checked}
                  setFormFields={setFields}
                  saveAddress={saveAddress}
                  merchantId={cart.site.amazon_merchant_id}
                  publicKeyId={cart.site.amazon_sandbox_public_key_id}
                />
              )}

            {!["google_pay", "amazon_pay", "amazon_pay_sandbox"].includes(
              tipoPago
            ) && (
              <Button
                variant="contained"
                className={classes.boton}
                color="primary"
                type="submit"
                onClick={() =>
                  handlePurchase({
                    id_pedido: cart.id,
                    forma_pago: tipoPago,
                    fields: fields,
                    shippingCountry: shippingCountry,
                    billingCountry: billingCountry,
                    facturacion: checked,
                    cart: cart,
                  })
                }
                disabled={loading}
              >
                {loading ? "Enviando a la forma de pago…" : "Pagar"}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-6 d-flex flex-column flex-items-start flex-justify-start p-6 color-bg-secondary">
        <div className="" style={{ maxWidth: 572 }}>
          <div className="d-none d-md-block text-right">
            <Link
              to={`/cesta/${localStorage.getItem("tmp")}`}
              className="d-inline-flex flex-items-center Link--secondary no-underline mb-4 f3"
              style={{ height: 70 }}
            >
              <ArrowLeftIcon /> <span className="ml-2">Volver a la cesta</span>
            </Link>
          </div>
          {cart.lines.map((line) => (
            <div key={line.id} className="d-flex flex-items-center mb-3">
              <div
                className="d-flex flex-items-center flex-justify-center border rounded-2 color-bg-primary position-relative"
                style={{
                  minWidth: 81,
                  maxWidth: 81,
                  minHeight: 81,
                  maxHeight: 81,
                  flexBasis: "81px",
                }}
              >
                <img
                  src={line.product.image}
                  alt={line.product.title}
                  style={{ maxHeight: 60, maxWidth: 60 }}
                  draggable={false}
                />
                <div
                  className="position-absolute top-0 right-0"
                  style={{ transform: "translateX(50%) translateY(-50%)" }}
                >
                  <span className="Counter">{line.quantity}</span>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row flex-md-items-center">
                <div className="ml-4 flex-1">
                  <div>
                    <span className="d-none d-md-block f5 text-bold mb-0">
                      {line.product.title}
                    </span>
                    <span className="d-md-none f6 text-bold mb-0">
                      {line.product.title}
                    </span>
                  </div>
                  <p className="d-none d-sm-block note mb-0">
                    {line.product.description.length > 150
                      ? `${line.product.description.substring(0, 100)}...`
                      : line.product.description}
                  </p>
                </div>
                <div className="d-flex flex-items-center mt-1 mt-md-0 ml-4">
                  <p className="text-md-right no-wrap">
                    {(line.quantity * line.pvp).toFixed(2)} €
                  </p>
                </div>
              </div>
            </div>
          ))}
          {cart.totales.descuento_aplicado > 0 && (
            <div className="d-flex flex-items-center flex-justify-between mt-2 pt-4 border-top">
              <p className="f4">Descuento</p>
              <div className="ml-4">
                <p className="f4">
                  {cart.totales.descuento_aplicado.toFixed(2)} €
                </p>
              </div>
            </div>
          )}
          {cart.totales.coste_envio_neto > 0 && (
            <div className="d-flex flex-items-center flex-justify-between mt-2 pt-4 border-top">
              <p className="f4">Envío</p>
              <div className="ml-4">
                <p className="f4">{cart.totales.coste_envio_neto} €</p>
              </div>
            </div>
          )}

          <div className="d-flex flex-items-center flex-justify-between mt-2 pt-4 border-top">
            <p className="f2">Total</p>
            <div className="ml-4">
              <p className="f2 no-wrap">
                {cart.totales.total_neto.toFixed(2)} €
              </p>
            </div>
          </div>
          {buyError && <Alert severity="warning">{buyError}</Alert>}
        </div>
      </div>
      <RedsysForm formData={redsysData} />
      <PaypalForm formData={paypalData} />
    </div>
  );
}
