// @ts-nocheck
import { useAccount } from "context/account.context";
import { useMemo } from "react";
import { useCallback, useState } from "react";
// import { useAccount } from "context/account";

const baseURL =
  process.env.REACT_APP_API_URL || "https://buy.bigbenespana.es/restapi/api";

const checkStatus = async (response, isLoggedIn) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (isLoggedIn && response.status === 401) {
    localStorage.removeItem("token");
    window.location.reload();
  }
  const error = new Error(response.statusText);
  // @ts-ignore
  error.response = await response.json();
  throw error;
};

const parseJSON = (response) => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const contentType = response.headers.get("Content-Type");
  if (contentType && contentType.indexOf("text/html") !== -1) {
    return response.text();
  }
  return response.json();
};

const emptyObject = {};
const voidFunction = () => {};

export default function useMutation({
  url = "",
  options = emptyObject,
  onCompleted = voidFunction,
  onError = voidFunction,
}) {
  const { isLoggedIn } = useAccount(); //ESTO LUEGO HAY QUE PONERLO
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(undefined);

  const fetchOptions = useMemo(
    () => ({
      method: "POST",
      ...options,
      headers: {
        ...options.headers,
        Authorization: isLoggedIn
          ? `Bearer ${localStorage.getItem("token")}`
          : undefined,
        "Content-Type": "application/json",
      },
    }),
    [options, isLoggedIn]
  );

  const fetchData = useCallback(
    async (bodyParams = {}) => {
      setError(undefined);
      setLoading(true);
      try {
        const response = await fetch(`${baseURL}${url}`, {
          ...fetchOptions,
          body: JSON.stringify({
            ...fetchOptions.body,
            ...bodyParams,
          }),
        })
          .then((res) => checkStatus(res, isLoggedIn))
          .then(parseJSON);

        setData(response);
        setLoading(false);
        onCompleted(response);
      } catch (err) {
        const message = err.response ? err.response.message : err.message;
        setError(message);
        setLoading(false);
        onError(message);
      }
    },
    [url, fetchOptions, onCompleted, onError, isLoggedIn]
  );

  return [fetchData, { data, loading, error }];
}
