import React from "react";
import { Switch, Route } from "react-router-dom";

import ViewPedido from "./pages/ViewPedido";
import ViewFinCompra from "./pages/ViewFinCompra";
import ViewPedidoError from "./pages/ViewPedidoError";
import AmazonCheckoutRoute from "./pages/AmazonCheckout";

export default function TramitarPage() {
  return (
    <Switch>
      <Route path="/pedido/error/:tmp" exact component={ViewPedidoError} />
      <Route path="/pedido/tramitar/" exact component={ViewPedido} />
      <Route
        path="/pedido/amazon/checkout/:tmp"
        exact
        component={AmazonCheckoutRoute}
      />
      <Route path="/pedido/fin" exact component={ViewFinCompra} />
    </Switch>
  );
}
