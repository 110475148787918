import { useCallback, useRef } from "react";
import { useMutation } from "hooks";
import {
  useCartDispatch,
  UPDATE_LINE_QUANTITY,
  DELETE_LINE,
} from "context/cart.context";
import {
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PropTypes from "prop-types";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  continuar: {
    marginTop: "5%",
    fontWeight: "bold",
    color: "#1c1c1c",
    borderColor: "#1c1c1c",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#d4d4d4",
      boxShadow: "none",
    },
  },
  precio: {
    fontWeight: "bold",
    "@media (max-width:400px)": {
      textAlign: "center",
    },
  },
  heroContent: {
    padding: theme.spacing(3, 0, 1),
  },
  linped: {
    marginBottom: "16px",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  alignDataCesta: {
    textAlign: "left",
    "@media (max-width:400px)": {
      textAlign: "right",
    },
  },
}));

export default function CartLineListItem({ line }) {
  const classes = useStyles();
  const dispatch = useCartDispatch();
  const [quantity, setQuantity] = useState(line.quantity);
  const quantityInputRef = useRef();
  const [sinStock, setSinStock] = useState(false);

  const handleUpdateQuantity = useCallback(
    (response) => {
      setQuantity(response.quantity);

      response.cantidad_disponible === 0
        ? setSinStock(true)
        : setSinStock(false);

      dispatch({
        type: UPDATE_LINE_QUANTITY,
        payload: {
          id: response.id_linea,
          quantity: parseInt(response.quantity, 10),
          totales: response.totales,
        },
      });
      if (quantityInputRef.current) {
        quantityInputRef.current.focus();
      }
    },
    [dispatch]
  );

  const [updateLineQuantity, { loading: updatingLineQuantity }] = useMutation({
    url: "/pedidos/line/quantity",
    options: { method: "PUT" },
    onCompleted: handleUpdateQuantity,
  });

  const handleDeleteLine = useCallback(
    (response) => {
      dispatch({ type: DELETE_LINE, payload: { id: response.id_linea } });
    },
    [dispatch]
  );

  const [deleteLine, { loading: deletingLine }] = useMutation({
    url: "/pedidos/line",
    options: { method: "DELETE" },
    onCompleted: handleDeleteLine,
  });

  const onQuantityInputChange = ({ target }) => {
    updateLineQuantity({
      id_linea: line.id,
      quantity: parseInt(target.value, 10),
    });
  };

  return (
    <div className="Box color-shadow-small mb-3">
      <div className="Box-body p-4">
        <div className="d-flex flex-column flex-md-row flex-items-center">
          <div
            className="d-flex flex-items-center flex-justify-center mb-4 mb-md-0"
            style={{
              minWidth: 120,
              maxWidth: 120,
              minHeight: 120,
              maxHeight: 120,
              flexBasis: "120px",
            }}
          >
            <img
              src={line.product.image}
              alt={line.product.title}
              style={{ maxHeight: 120, maxWidth: 120 }}
              draggable={false}
            />
          </div>
          <div className="d-flex flex-items-center flex-1">
            <div className="flex-1 ml-md-4 mr-4">
              <p className="f5 text-bold">{line.product.title}</p>
              <p className="color-text-secondary">
                {line.product.description.length > 150
                  ? `${line.product.description.substring(0, 150)}...`
                  : line.product.description}
              </p>
              <p>
                <button
                  type="button"
                  className="btn-link"
                  onClick={() => deleteLine({ id_linea: line.id })}
                  disabled={updatingLineQuantity || deletingLine}
                >
                  Eliminar
                </button>
              </p>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row">
            <div className="d-flex flex-items-center flex-justify-center">
              <IconButton
                aria-label="delete"
                className="mr-2"
                onClick={() =>
                  updateLineQuantity({
                    id_linea: line.id,
                    quantity: line.quantity - 1,
                  })
                }
                disabled={
                  line.quantity <= 1 || updatingLineQuantity || deletingLine
                }
              >
                <RemoveIcon />
              </IconButton>
              <TextField
                inputRef={quantityInputRef}
                type="number"
                style={{ width: "55px", textAlign: "center" }}
                inputProps={{ style: { textAlign: "center" } }}
                size="small"
                value={quantity}
                onChange={onQuantityInputChange}
                disabled={updatingLineQuantity || deletingLine}
                error={sinStock}
                helperText={sinStock ? "Sin stock" : null}
              />
              <IconButton
                aria-label="delete"
                className="ml-2"
                onClick={() =>
                  updateLineQuantity({
                    id_linea: line.id,
                    quantity: line.quantity + 1,
                  })
                }
                disabled={updatingLineQuantity || deletingLine}
              >
                <AddIcon />
              </IconButton>
            </div>
            <div className="d-flex flex-items-center pl-6">
              <Typography
                style={{ fontWeight: "500" }}
                className={classes.alignDataCesta}
                variant="h6"
              >
                {(line.pvp * line.quantity).toFixed(2)}€
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CartLineListItem.propTypes = {
  line: PropTypes.object.isRequired,
};
