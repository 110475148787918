import React from "react";
import { AppBar } from "@material-ui/core";
import { useCart } from "context/cart.context";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Menu, MenuItem, Avatar } from "@material-ui/core";
import { Helmet } from "react-helmet";

import {
  useAccount,
  useAccountDispatch,
  LOGOUT,
} from "context/account.context";

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: "black",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  login: {
    margin: theme.spacing(1, 1.5),
    color: "white",
    borderColor: "white",
    "&:hover": {
      backgroundColor: "#1c1c1c",
      boxShadow: "none",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const cart = useCart();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const account = useAccount();
  const dispatch = useAccountDispatch();

  const handleAccountMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem("token");
    dispatch({
      type: LOGOUT,
    });
    window.location.reload();
  };

  const handleMisPedidos = () => {
    setAnchorEl(null);
    window.location.href = "/user/pedidos";
  };

  const handleMiCesta = () => {
    setAnchorEl(null);
    window.location.href = "/cesta/" + localStorage.getItem("tmp");
  };

  const return_shop = () => {
    var url = "";
    switch (cart.site.id) {
      case 1:
        url = process.env.REACT_APP_NACON_URL || "https://nacongamers.es";
        break;
      case 2:
        url = process.env.REACT_APP_BBESPANA_URL || "https://bigbenespana.es";
        break;
      default:
        break;
    }
    window.location.href =
      url + "/cesta/return_to_cesta/" + localStorage.getItem("tmp");
  };

  return (
    <>
      <Helmet>
        {/* Start of HubSpot Embed Code */}
        {cart.site.id === 1 && (
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-eu1.hs-scripts.com/24977814.js"
          ></script>
        )}
        {/* End of HubSpot Embed Code  */}
      </Helmet>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <div className="container-xl width-full d-flex flex-justify-between flex-items-center p-responsive py-3">
          <div
            className="d-flex flex-items-center flex-1"
            style={{ cursor: "pointer" }}
            onClick={() => return_shop()}
          >
            {cart.site.image && (
              <img src={cart.site.image} alt="logo" height="35px" />
            )}
          </div>
          {account.isLoggedIn && (
            <div>
              <Button
                className={classes.login}
                id="demo-positioned-button"
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleAccountMenuClick}
              >
                <Avatar
                  style={{ backgroundColor: "#ffffff", color: "#000000" }}
                >
                  {account.user.email.substring(0, 2)}
                </Avatar>
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {localStorage.getItem("tmp") && (
                  <MenuItem onClick={handleMiCesta}>Mis cesta</MenuItem>
                )}
                <MenuItem onClick={handleMisPedidos}>Mis pedidos</MenuItem>
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </AppBar>
    </>
  );
}
