import { Modal, Backdrop, Slide, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useAccount } from "context/account.context";

const useStyles = makeStyles({
  inputs: {
    width: "100%",
  },
  submitButton: {
    width: "100%",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default function AddressModal({ open, onClose, onSelect }) {
  const classes = useStyles();
  const account = useAccount();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 250 }}
      closeAfterTransition
    >
      <Slide in={open} direction="up">
        <div
          className="d-flex flex-column flex-items-center flex-justify-center width-full py-4 p-responsive"
          style={{ outline: "none" }}
        >
          <div className="Box width-full" style={{ maxWidth: 365 }}>
            <div className="Box-body">
              <h2 className="text-center mb-3">Tus direcciones guardadas</h2>
              <p>Selecciona una de las siguientes direcciones guardadas:</p>
              {account.user.direcciones.map((address) => (
                <div
                  key={address.id}
                  role="button"
                  className="Box mt-3"
                  onClick={() => onSelect(address)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="Box-body">
                    <p>
                      {address.nombre} {address.apellidos}
                    </p>
                    <p className="mb-0">{address.direccion}</p>
                    <p>{address.direccion2}</p>
                    <p>
                      {address.localidad}, {address.provincia}, {address.cp},{" "}
                      {address.pais}
                    </p>
                    <p className="mb-0">{address.telefono}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Slide>
    </Modal>
  );
}

AddressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};
