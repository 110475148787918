/* global amazon */
import { useEffect, useState } from "react";
import { useCart } from "context/cart.context";
import { useMutation } from "hooks";
import { useCallback } from "react";
import { useAccount } from "context/account.context";

export default function AmazonPayButton({
  formFields,
  shippingCountry,
  billingCountry,
  facturacion,
  setFormFields,
  saveAddress,
  merchantId,
  publicKeyId,
}) {
  const [amazonPayButton, setAmazonPayButton] = useState(undefined);
  const cart = useCart();
  const account = useAccount();

  const handleSignature = useCallback(
    ({ payload, signature }) => {
      if (amazonPayButton) {
        amazonPayButton.initCheckout({
          createCheckoutSessionConfig: {
            payloadJSON: JSON.stringify(payload),
            signature,
          },
        });
      }
    },
    [amazonPayButton]
  );

  const onSignatureError = useCallback(
    (error) => {
      setFormFields(error.fields);
    },
    [setFormFields]
  );

  const [generateSignature] = useMutation({
    url: "/pedidos/buy",
    onCompleted: handleSignature,
    onError: onSignatureError,
  });

  const handleAmazonButtonClick = useCallback(
    (event) => {
      generateSignature({
        id_pedido: cart.id,
        forma_pago: "amazon_pay",
        fields: formFields,
        shippingCountry,
        billingCountry,
        facturacion,
        cart: cart,
        saveAddress,
        id_user: account.isLoggedIn ? account.user.id : undefined,
      });
    },
    [
      billingCountry,
      cart,
      formFields,
      generateSignature,
      shippingCountry,
      facturacion,
      saveAddress,
      account,
    ]
  );

  useEffect(() => {
    if (amazonPayButton) {
      amazonPayButton.onClick(handleAmazonButtonClick);
    }
  }, [amazonPayButton, handleAmazonButtonClick]);

  useEffect(() => {
    setAmazonPayButton(
      amazon.Pay.renderButton("#AmazonPayButton", {
        merchantId: merchantId,
        publicKeyId: publicKeyId,
        ledgerCurrency: "EUR",
        checkoutLanguage: "es_ES",
        productType: "PayAndShip",
        placement: "Checkout",
        buttonColor: "Gold",
        sandbox: true,
      })
    );
  }, []);

  return <div id="AmazonPayButton" />;
}
