import { createGlobalStyle } from "styled-components";
// import { createGlobalStyle, keyframes } from "styled-components";

// const autofill = keyframes`
//   to {
//     color: inherit;
//     background: inherit;
//   }
// `;

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    margin:0;
  }

  body,
  #root {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

`;

export default GlobalStyle;
