import styled from "styled-components";

export default styled.div`
  padding: ${({ collapse }) => (collapse ? "0 16px" : "12px 16px")};
  height: ${({ collapse }) => (collapse ? 0 : "auto")};
  visibility: ${({ collapse }) => (collapse ? "hidden" : "visible")};
  transition: all 150ms linear;
  overflow: ${({ collapse }) => (collapse ? "hidden" : "initial")};

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & + & {
    border-top: 1px solid #eaedf3;
  }

  & h3 {
    margin: 0;
    font-size: 18px;
    line-height: 28px;
  }

  & h3 + *,
  & h4 + *,
  & h5 + * {
    margin-top: 4px;
  }

  & h4 {
    margin: 0;
    font-size: 17px;
    line-height: 26px;
  }

  & h5 {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
  }

  & .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  & .col-md-1,
  & .col-md-2,
  & .col-md-3,
  & .col-md-4,
  & .col-md-5,
  & .col-md-6,
  & .col-md-7,
  & .col-md-8,
  & .col-md-9,
  & .col-md-10,
  & .col-md-11,
  & .col-md-12 {
    padding: 0 10px;
  }
`;
