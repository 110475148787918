import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useCart } from "context/cart.context";
import { useMutation } from "hooks";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import paymentError from "files/img/payment_error.png";

export default function ViewPedidoError({ match }) {
  const history = useHistory();
  const cart = useCart();
  const [loaded, setLoeaded] = useState(false);

  const handleInitialLoad = useCallback(
    ({ pedido: { id, sitio, lineas_producto, totales }, tmp }) => {
      setLoeaded(true);
      history.push("/cesta/" + tmp);
    },
    [history]
  );

  const [manageError] = useMutation({
    url: `/pedidos/pay/error`,
    onCompleted: handleInitialLoad,
  });

  useEffect(() => {
    if (cart.id !== undefined && loaded === false) {
      manageError({ tmp: match.params.tmp });
    }
  }, [cart, manageError, loaded, match.params.tmp]);

  return (
    <React.Fragment>
      <div style={{ margin: "0px auto auto auto", textAlign: "center" }}>
        <h1 style={{ margin: "50px" }}>
          El proceso de pago ha fallado, redirigiendo a tu cesta
        </h1>
        <CircularProgress color="inherit" size={100} />
      </div>
      <img
        style={{ width: "30%", maxWidth: "500px", margin: "auto" }}
        src={paymentError}
        alt="Payment error"
      />
      ;
    </React.Fragment>
  );
}
