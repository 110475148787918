import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import GlobalStyle from "./global-styles";
ReactDOM.render(
  <React.Fragment>
    <Routes />
    <GlobalStyle />
  </React.Fragment>,
  document.getElementById("root")
);
