import React from "react";
import { useRef, useEffect } from "react";

export default function PaypalForm({ formData }) {
  const paypalFormRef = useRef();

  // On redsys form data is loaded submit the form.
  useEffect(() => {
    if (formData.paypal_url.length > 0) {
      paypalFormRef.current.submit();
    }
  }, [formData, paypalFormRef]);

  return formData.paypal_url.length > 0 ? (
    <form
      id="paypal_form"
      ref={paypalFormRef}
      action={formData.paypal_url}
      method="post"
    >
      {/* GENERALES */}
      <input type="hidden" name="cmd" value={formData.paypal_cmd} />
      <input type="hidden" name="no_note" value={formData.paypal_no_note} />
      <input type="hidden" name="lc" value={formData.paypal_lc} />
      <input
        type="hidden"
        name="currency_code"
        value={formData.paypal_currency_code}
      />
      <input type="hidden" name="business" value={formData.paypal_business} />
      <input
        type="hidden"
        name="shopping_url"
        value={formData.paypal_shopping_url}
      />
      <input type="hidden" name="bn" value={formData.paypal_bn} />
      <input type="hidden" name="rm" value={formData.paypal_rm} />
      <input type="hidden" name="upload" value={formData.paypal_upload} />

      {/* COMPRA */}
      {formData.paypal_items_numbers.map((item_number) => (
        <input
          type="hidden"
          name={item_number.name}
          value={item_number.value}
        />
      ))}
      {formData.paypal_items_quantities.map((item_quantity) => (
        <input
          type="hidden"
          name={item_quantity.name}
          value={item_quantity.value}
        />
      ))}
      {formData.paypal_items_names.map((item_name) => (
        <input type="hidden" name={item_name.name} value={item_name.value} />
      ))}
      <input type="hidden" name="amount_1" value={formData.paypal_amount_1} />

      {/* COMPRADOR */}
      <input
        type="hidden"
        name="first_name"
        value={formData.paypal_first_name}
      />
      <input type="hidden" name="last_name" value={formData.paypal_last_name} />
      <input
        type="hidden"
        name="payer_email"
        value={formData.paypal_payer_email}
      />

      {/* ACTIONS */}
      <input
        type="hidden"
        name="notify_url"
        value={formData.paypal_notify_url}
      />
      <input
        type="hidden"
        name="cancel_return"
        value={formData.paypal_cancel_return}
      />
      <input type="hidden" name="return" value={formData.paypal_return} />

      <input type="hidden" name="rm" value={"2"} />
    </form>
  ) : (
    <React.Fragment></React.Fragment>
  );
}
