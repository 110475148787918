import { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { useQuery } from "hooks";
import { useCallback } from "react";
import { CircularProgress } from "@material-ui/core";
import { useMemo } from "react";

export const LOAD_USER = "LOAD_USER";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";

const initialState = {
  user: undefined,
  isLoggedIn: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case LOAD_USER:
      return { ...state, user: action.payload, isLoggedIn: true };
    case LOGOUT:
      return initialState;
    case LOGIN:
      return { ...state, isLoggedIn: true };
    default:
      return state;
  }
};

export const AccountContext = createContext();
export const AccountDispatchContext = createContext();

export const AccountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const token = useMemo(() => localStorage.getItem("token"), []);

  const onCompleted = useCallback(
    (data) => {
      dispatch({ type: LOAD_USER, payload: data });
    },
    [dispatch]
  );

  useQuery({
    url: "/pedidos/viewer",
    onCompleted,
    onError: console.log,
    skip: !token,
  });

  return (
    <AccountContext.Provider value={state}>
      <AccountDispatchContext.Provider value={dispatch}>
        {state.isLoggedIn || !token ? (
          children
        ) : (
          <div className="d-flex flex-items-center flex-justify-center flex-1">
            <CircularProgress />
          </div>
        )}
      </AccountDispatchContext.Provider>
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};

export const useAccountDispatch = () => {
  const context = useContext(AccountDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAccountDispatch must be used within an AccountProvider"
    );
  }
  return context;
};
