import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Moment from "react-moment";

const baseURL =
  process.env.REACT_APP_API_URL || "https://buy.bigbenespana.es/restapi/api";

const useStyles = makeStyles((theme) => ({
  fs_120p: {
    fontSize: "120%",
  },

  fs_200p: {
    fontSize: "200%",
  },

  fs_300p: {
    fontSize: "300%",
  },

  fs_400p: {
    fontSize: "400%",
  },

  talign_c: {
    textAlign: "center",
  },

  margin_b30: {
    marginBottom: "30px",
  },

  enterprise_img: {
    width: "100%",
    marginBottom: "30px",
  },

  status_img: {
    width: "100%",
    marginBottom: "30px",
  },

  cesta: {
    width: "80%",
    border: "none",
    margin: "auto",
    borderBottom: "2px solid #bdc1c6",
    "& td": {
      verticalAlign: "top",
      minWidth: "50px",
    },
  },

  totales: {
    width: "80%",
    margin: "auto",
    borderBottom: "2px solid #bdc1c6",
    paddingTop: "20px",
    "& p": {
      textAlign: "right",
    },
  },

  datos_envio: {
    width: "80%",
    textAlign: "center",
    margin: "auto",
    paddingTop: "20px",
  },

  envio_col: {
    verticalAlign: "top",
    display: "inline-block",
    minWidth: "30px",
  },

  bottom: {
    paddingTop: "40px",
    width: "80%",
    margin: "auto",
    textAlign: "center",
  },
}));
export default function ViewFinCompra() {
  const classes = useStyles();
  const [pedido, setPedido] = useState(null);

  const loadData = () => {
    fetch(`${baseURL}/pedidos/cesta/tmp/${localStorage.getItem("tmp")}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then(({ pedido }) => {
        setPedido(pedido);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return pedido == null ? (
    <React.Fragment>
      <div style={{ margin: "0px auto auto auto", textAlign: "center" }}>
        <h1 style={{ margin: "50px" }}>Cargando pedido</h1>
        <CircularProgress color="inherit" size={100} />
      </div>
      ;
    </React.Fragment>
  ) : (
    <Paper
      style={{
        justifyContent: "center",
        alignContent: "center",
        alignSelf: "center",
        marginTop: "20px",
        padding: "40px",
        textAlign: "center",
      }}
    >
      <div>
        <div className={classes.enterprise_img}>
          <img
            style={{ maxWidth: "200px" }}
            src={`https://buy.bigbenespana.es/devrestapi/src/public/storage/${pedido.sitio.corp_logo_black}`}
            alt="corp_logo"
          />
        </div>
        <div className={classes.status_img}>
          <img
            style={{ maxWidth: "200px" }}
            src="https://buy.bigbenespana.es/devrestapi/src/public/storage/img/order_confirm.png"
            alt="Order confirm"
          />
        </div>

        <h1 className={(classes.fs_400p, classes.talign_c, classes.margin_b30)}>
          ¡Pedido confirmado!
        </h1>

        <p className={(classes.fs_120p, classes.talign_c, classes.margin_b30)}>
          Muchas gracias por su compra, el pago de su pedido ha sido confirmado
          y ya sido notificado a nuestros agentes, en breve recibirá un correo
          con la información de seguimiento de su pedido.
        </p>

        <h2 className={(classes.fs_300p, classes.talign_c)}>
          Número pedido: {pedido.numero}
        </h2>

        <h3 className={(classes.fs_200p, classes.talign_c, classes.margin_b30)}>
          <Moment format="DD/MM/YYYY HH:mm">{pedido.created_at}</Moment>
        </h3>
      </div>

      <div>
        <table className={classes.cesta}>
          <tbody>
            {pedido.lineas_producto.map((line, key) => (
              <tr key={"lin_ped_" + key}>
                <td>
                  <img
                    style={{ height: "120px", margin: "0px 40px 40px 0px" }}
                    src={line.producto.imagen_del_producto}
                    alt="img"
                  />
                </td>
                <td>
                  <p>
                    <b>{line.producto.nombre_de_producto_es}</b>
                  </p>
                  <p>{line.producto.referencia}</p>
                  <p>
                    {line.producto.descripcion_es.length < 350
                      ? line.producto.descripcion_es
                      : line.producto.descripcion_es.substring(0, 349) + " ..."}
                  </p>
                </td>
                <td style={{ minWidth: "100px" }}>
                  <p>{line.pvp_unidad} €</p>
                </td>
                <td>
                  <p>{line.unidades} uds</p>
                </td>
                <td style={{ minWidth: "100px" }}>
                  <p>{line.pvp_unidad * line.unidades} €</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={classes.totales}>
        <p>
          Subtotal: <b>{pedido.total_base_bruto.toFixed(2)}€</b>
        </p>
        <p>
          IVA: <b>{pedido.total_base_iva.toFixed(2)}€</b>
        </p>
        {pedido.descuento_aplicado && (
          <p>
            Descuento: <b>{pedido.descuento_aplicado.toFixed(2)}€</b>
          </p>
        )}
        <p>
          Envío:{" "}
          <b>{pedido.coste_envio_neto ? pedido.coste_envio_neto : "0.00"}€</b>
        </p>
        <p>
          Total: <b>{pedido.total_neto}€</b>
        </p>
      </div>
      <div className={classes.datos_envio}>
        <div className={classes.envio_col}>
          <p>
            Comprador: <b>{pedido.email}</b>
          </p>
          <p>
            Número de orden: <b>#{pedido.numero}</b>
          </p>
          <p>
            Fecha de compra:{" "}
            <b>
              <Moment format="DD/MM/YYYY HH:mm">{pedido.created_at}</Moment>
            </b>
          </p>
        </div>
        <div className={classes.envio_col}></div>
        <div className={classes.envio_col}>
          <p>Datos de envío: </p>
          <p>
            <b>
              {pedido.nombre_envio} {pedido.apellidos_envio}
            </b>
          </p>
          <p>
            <b>{pedido.direccion_envio}</b>
          </p>
          <p>
            <b>
              {pedido.localidad_envio}, {pedido.provincia_envio},{" "}
              {pedido.cp_envio}, {pedido.pais_envio}
            </b>
          </p>
        </div>
      </div>
      <div className={classes.bottom}>
        ¿Tienes algún problema o duda? Puedes contactar con nosotros en nuestro{" "}
        <a
          href={(() => {
            switch (pedido.sitio.id) {
              case 1:
                return " https://www.nacongamers.es/soporte/";
              case 2:
                return " https://www.bigbenespana.es/soporte/";
              default:
                return " https://www.bigbenespana.es/soporte/";
            }
          })()}
        >
          centro de soporte
        </a>
        .
      </div>
      <div className={classes.bottom}>
        <p>{pedido.sitio.nombre} © 2021 Todos los derechos reservados</p>
        <p>Calle Musgo 5, 2ª planta. 28023. Madrid</p>
      </div>
    </Paper>
  );
}
