import { useEffect, useState, useCallback } from "react";
import { useMutation } from "hooks";
import { useCart } from "context/cart.context";

import { makeStyles, TextField, Button } from "@material-ui/core";

import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

const useStyles = makeStyles({
  inputs: {
    width: "100%",
  },
  submitButton: {
    marginTop: "20px",
    width: "100%",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default function Register() {
  const cart = useCart();
  const classes = useStyles();

  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [policies, setPolicies] = useState(false);
  const [cookies, setCookies] = useState(false);
  const [condicionesVenta, setCondicionesVenta] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.href = "/pedido/tramitar";
    }
  }, []);

  const handleRegisterSuccess = useCallback(({ access_token }) => {
    setError(undefined);
    localStorage.setItem("token", access_token);
    // history.push("/pedido/tramitar/");
    window.location.reload();
  }, []);

  const [register, { loading }] = useMutation({
    url: "/register",
    onCompleted: handleRegisterSuccess,
    onError: (message) => {
      console.log(message);
      setError(message);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password === password2) {
      setPasswordError(false);
      register({
        nombre,
        apellidos,
        email,
        password,
        policies,
        cookies,
        condicionesVenta,
        id_sitio_registro: cart.site.id,
      });
    } else {
      setPasswordError(true);
    }
  };

  return (
    <div
      className="d-flex flex-column flex-items-center flex-justify-center width-full py-4 p-responsive"
      style={{ outline: "none" }}
    >
      <div className="Box width-full" style={{ maxWidth: 665 }}>
        <div className="Box-body">
          <h1 className="text-center mb-4">Darse de alta</h1>
          {error && (
            <div className="flash mb-3 flash-error">
              <div className="text-center text-red-500">{error}</div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <TextField
                type="email"
                name="new-email"
                className={classes.inputs}
                label="Email"
                size="small"
                variant="outlined"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                autoComplete="off"
                spellCheck={false}
                autoFocus
                required
              />
            </div>
            <div className="mb-3">
              <TextField
                type="text"
                name="nombre"
                className={classes.inputs}
                label="Nombre"
                size="small"
                variant="outlined"
                value={nombre}
                onChange={({ target }) => setNombre(target.value)}
                autoComplete="off"
                spellCheck={false}
                autoFocus
                required
              />
            </div>
            <div className="mb-3">
              <TextField
                type="text"
                name="apellidos"
                className={classes.inputs}
                label="Apellidos"
                size="small"
                variant="outlined"
                value={apellidos}
                onChange={({ target }) => setApellidos(target.value)}
                autoComplete="off"
                spellCheck={false}
                autoFocus
                required
              />
            </div>
            <div className="mb-3">
              <TextField
                type="password"
                name="new-password"
                className={classes.inputs}
                label="Contraseña"
                size="small"
                variant="outlined"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                autoComplete="off"
                error={passwordError}
                helperText={passwordError && "Las contraseñas no coinciden"}
                spellCheck={false}
                required
              />
            </div>
            <div className="mb-3">
              <TextField
                type="password"
                name="new-password2"
                className={classes.inputs}
                label="Repita la contraseña"
                size="small"
                variant="outlined"
                value={password2}
                onChange={({ target }) => setPassword2(target.value)}
                autoComplete="off"
                error={passwordError}
                helperText={passwordError && "Las contraseñas no coinciden"}
                spellCheck={false}
                required
              />
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={policies}
                    required
                    onChange={(e) => setPolicies(e.target.checked)}
                  />
                }
                label={
                  <a
                    target="_blank"
                    href={
                      cart.site.url_policies ||
                      "https://www.bigbenespana.es/info/politica_privacidad"
                    }
                  >
                    He leído y acepto los términos y condiciones de la política
                    de privacidad
                  </a>
                }
              />
            </FormGroup>
            {/* <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={cookies}
                    required
                    onChange={(e) => setCookies(e.target.checked)}
                  />
                }
                label={
                  <a
                    target="_blank"
                    href={
                      cart.site.url_cookies ||
                      "https://www.bigbenespana.es/info/politica_cookies"
                    }
                  >
                    He leído y acepto los términos y condiciones de la política
                    de cookies
                  </a>
                }
              />
            </FormGroup> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={condicionesVenta}
                    required
                    onChange={(e) => setCondicionesVenta(e.target.checked)}
                  />
                }
                label={
                  <a
                    target="_blank"
                    href={
                      cart.site.url_condiciones_venta ||
                      "https://www.bigbenespana.es/info/condiciones_venta"
                    }
                  >
                    He leído y acepto los términos y condiciones de las
                    condiciones de venta
                  </a>
                }
              />
            </FormGroup>
            <Button
              variant="contained"
              className={classes.submitButton}
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? "Registrando..." : "Registrarse"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
