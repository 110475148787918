import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "hooks";
import {
  useCart,
  useCartDispatch,
  LOAD_CART,
  LOAD_CART_ERROR,
  UPDATE_DISCOUNT,
} from "context/cart.context";
import { useQuery } from "hooks";
import {
  CssBaseline,
  CircularProgress,
  TextField,
  withStyles,
  Button,
} from "@material-ui/core";
import { CartLineListItem } from "components";
import { useHistory } from "react-router-dom";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(TextField);

export default function CartRoute({ match }) {
  const cart = useCart();
  const dispatch = useCartDispatch();
  const history = useHistory();

  const [codePromo, setCodePromo] = useState("");
  const [promoError, setPromoError] = useState(null);
  const [loadingPromo, setLoadingPromo] = useState(null);

  useEffect(() => {
    localStorage.setItem("tmp", match.params.tmp);
  }, [match.params.tmp]);

  useEffect(() => {
    if (cart.promocion) setCodePromo(cart.promocion.code);
  }, [cart]);

  const handleInitialLoad = useCallback(
    ({
      pedido: {
        id,
        sitio,
        lineas_producto,
        id_pedido_estado,
        total_neto,
        total_bruto,
        total_iva,
        total_base_neto,
        total_descuento_neto,
        coste_envio_neto,
        descuento_aplicado,
        promocion,
      },
    }) => {
      localStorage.setItem("tmp", match.params.tmp);
      dispatch({
        type: LOAD_CART,
        payload: {
          id,
          sitio,
          lineas_producto,
          id_pedido_estado,
          total_neto,
          total_bruto,
          total_iva,
          total_base_neto,
          total_descuento_neto,
          coste_envio_neto,
          descuento_aplicado,
          promocion,
        },
      });
    },
    [dispatch, match.params.tmp]
  );

  const handleCartError = useCallback(() => {
    dispatch({
      type: LOAD_CART_ERROR,
      payload:
        "https://www.bigbenespana.es/assets/img/home/BIGBEN_ESPANA-Negativo_ALTA.png",
    });
  }, [dispatch]);

  const { loading, error } = useQuery({
    url: `/pedidos/cesta/tmp/${match.params.tmp}`,
    onCompleted: handleInitialLoad,
    onError: handleCartError,
    skip: !match.params.tmp,
  });

  if ([3, 4, 5, 6, 10].includes(cart.id_pedido_estado)) {
    //si el pedido esta completado llevo a fin
    history.push("/pedido/fin");
  }

  if ([7, 8, 9, 11].includes(cart.id_pedido_estado)) {
    //si el pedido esta en un estado erroneo, envio a error
    history.push("/pedido/error/" + cart.tmp);
  }

  const handleUpdatePromo = useCallback(
    (response) => {
      setPromoError(null);
      setLoadingPromo(null);
      dispatch({
        type: UPDATE_DISCOUNT,
        payload: { totales: response.totales, promocion: response.promocion },
      });
    },
    [dispatch]
  );

  const handleBadPromo = useCallback((messageError) => {
    setPromoError(messageError);
    setLoadingPromo(null);
  }, []);

  const [updatePromo] = useMutation({
    url: "/pedidos/promocion/set",
    options: { method: "POST" },
    onCompleted: handleUpdatePromo,
    onError: handleBadPromo,
  });

  const [unsetPromo] = useMutation({
    url: "/pedidos/promocion/unset",
    options: { method: "POST" },
    onCompleted: handleUpdatePromo,
    onError: handleBadPromo,
  });

  if (error) return null;

  return (
    <div className="d-flex flex-1 flex-items-start">
      <div className="container-lg width-full p-responsive py-4">
        <CssBaseline />
        {/* Hero unit */}
        <div className="d-flex flex-justify-center mb-4 pb-4 border-bottom">
          <p className="f1 mb-0">Carrito de compra</p>
        </div>
        {/* End hero unit */}
        {/*START LINEAS PEDIDO */}
        {loading && (
          <div className="d-flex flex-items-center flex-justify-center mt-4">
            <CircularProgress color="inherit" />
          </div>
        )}
        {!loading &&
          cart.lines.map((line) => (
            <CartLineListItem key={line.id} line={line} />
          ))}
        {!loading && cart.lines.length === 0 && (
          <div className="container-lg p-responsive">
            <div className="d-flex flex-items-center flex-justify-center">
              <p className="f4 color-text-secondary">
                No hay productos en tu cesta
              </p>
            </div>
          </div>
        )}

        {!loading &&
          cart.lines.length > 0 &&
          cart.totales.descuento_aplicado > 0 && (
            <div className="d-flex flex-column flex-justify-center flex-items-end">
              <p className="f5">
                Descuento:{" "}
                {cart.totales.descuento_aplicado
                  ? cart.totales.descuento_aplicado.toFixed(2)
                  : null}
                €
              </p>
            </div>
          )}

        {!loading &&
          cart.lines.length > 0 &&
          cart.totales.coste_envio_neto > 0 && (
            <div className="d-flex flex-column flex-justify-center flex-items-end">
              <p className="f5">
                Envío:{" "}
                {cart.totales.coste_envio_neto
                  ? cart.totales.coste_envio_neto
                  : null}
                €
              </p>
            </div>
          )}

        {!loading && cart.lines.length > 0 && (
          <div className="d-flex flex-column flex-md-row flex-md-items-end">
            <div className="flex-1 mb-4 mb-md-0 mr-md-4">
              <div className="Box color-shadow-small">
                {!loadingPromo ? (
                  <div className="Box-body">
                    <CssTextField
                      onChange={(e) => {
                        setCodePromo(e.target.value);
                      }}
                      label="Código promocional"
                      placeholder="PROMO2020"
                      value={codePromo}
                      error={promoError ? true : false}
                      helperText={promoError}
                    />
                    <Button
                      style={{
                        marginLeft: "40px",
                        marginTop: "12px",
                        float: "right",
                      }}
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => {
                        setLoadingPromo(true);
                        updatePromo({
                          tmp: match.params.tmp,
                          code: codePromo,
                        });
                      }}
                    >
                      Validar
                    </Button>
                    {!cart.promocion && (
                      <Button
                        style={{
                          marginLeft: "40px",
                          marginTop: "12px",
                          float: "right",
                        }}
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={() => {
                          setCodePromo("");
                          setLoadingPromo(true);
                          unsetPromo({
                            tmp: match.params.tmp,
                          });
                        }}
                      >
                        ELIMINAR
                      </Button>
                    )}
                  </div>
                ) : (
                  <CircularProgress
                    style={{ margin: "20px" }}
                    color="inherit"
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-column flex-justify-center flex-items-end">
              <p className="h1 mb-1 text-right">
                {cart.totales.total_neto
                  ? cart.totales.total_neto.toFixed(2)
                  : 0.0}
                €
              </p>
              <Link to="/pedido/tramitar" className="btn-mktg">
                CONTINUAR CON LA COMPRA
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
