import React from "react";
import { useRef, useEffect } from "react";

export default function RedsysForm({ formData }) {
  const redsysFormRef = useRef();

  // On redsys form data is loaded submit the form.
  useEffect(() => {
    if (formData.url_Tpv.length > 0) {
      redsysFormRef.current.submit();
    }
  }, [formData, redsysFormRef]);

  return formData.url_Tpv.length > 0 ? (
    <form ref={redsysFormRef} action={formData.url_Tpv} method="post">
      <input
        type="hidden"
        name="Ds_SignatureVersion"
        value={formData.Ds_SignatureVersion}
      />
      <input
        type="hidden"
        name="Ds_MerchantParameters"
        value={formData.Ds_MerchantParameters}
      />
      <input type="hidden" name="Ds_Signature" value={formData.Ds_Signature} />
    </form>
  ) : (
    <React.Fragment></React.Fragment>
  );
}
