import { useState, useCallback } from "react";
import { useMutation } from "hooks";
import { useHistory } from "react-router-dom";
import { useCart } from "context/cart.context";
import {
  Modal,
  Backdrop,
  Slide,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  inputs: {
    width: "100%",
  },
  submitButton: {
    width: "100%",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default function AuthModal({ open, onClose }) {
  const cart = useCart();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const history = useHistory();

  const handleLoginSuccess = useCallback(({ access_token }) => {
    setError(undefined);
    localStorage.setItem("token", access_token);
    window.location.reload();
  }, []);

  const [login, { loading }] = useMutation({
    url: "/login",
    onCompleted: handleLoginSuccess,
    onError: (message) => {
      console.log(message);
      setError(message);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    login({ email, password });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 250 }}
      closeAfterTransition
    >
      <Slide in={open} direction="up">
        <div
          className="d-flex flex-column flex-items-center flex-justify-center width-full py-4 p-responsive"
          style={{ outline: "none" }}
        >
          <div className="Box width-full" style={{ maxWidth: 565 }}>
            <div className="Box-body">
              <div style={{ textAlign: "center", margin: "20px 0px" }}>
                <img
                  style={{ maxWidth: "400px" }}
                  src={
                    cart.site.corp_logo_black
                      ? `https://${cart.site.buy_url}/restapi/src/public/storage/${cart.site.corp_logo_black}`
                      : `https://${cart.site.buy_url}/restapi/src/public/storage/img/bb_logo_black.png`
                  }
                  alt="corp_logo"
                />
              </div>

              {error && (
                <div className="flash mb-3 flash-error">
                  <div className="text-center text-red-500">{error}</div>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <TextField
                    type="email"
                    name="new-email"
                    className={classes.inputs}
                    label="Email"
                    size="small"
                    variant="outlined"
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                    autoComplete="off"
                    spellCheck={false}
                    autoFocus
                    required
                  />
                </div>
                <div className="mb-3">
                  <TextField
                    type="password"
                    name="new-password"
                    className={classes.inputs}
                    label="Contraseña"
                    size="small"
                    variant="outlined"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                    autoComplete="off"
                    spellCheck={false}
                    required
                  />
                </div>
                <Button
                  variant="contained"
                  className={classes.submitButton}
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Iniciando sesión…" : "Iniciar sesión"}
                </Button>
              </form>
              <p className="note text-center mt-4 mb-0">
                ¿No tienes una cuenta?{" "}
                <button
                  type="button"
                  className="Link btn-link no-underline"
                  onClick={() => history.push("/user/registrar")}
                >
                  Registra una cuenta
                </button>
                .
              </p>
              <p className="text-center mb-4" style={{ marginTop: "40px" }}>
                <b>Tambien puedes iniciar sesión con tu cuentas de:</b>
              </p>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                {cart.site.id != 1 && (
                  <img
                    style={{ maxWidth: "200px" }}
                    src={`https://${cart.site.buy_url}/restapi/src/public/storage/img/na_logo_black.png`}
                    alt="corp_logo"
                  />
                )}
              </div>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                {cart.site.id != 2 && (
                  <img
                    style={{ maxWidth: "200px" }}
                    src={`https://${cart.site.buy_url}/restapi/src/public/storage/img/bb_logo_black.png`}
                    alt="corp_logo"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </Modal>
  );
}

AuthModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
